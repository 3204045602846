import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { HiPlay } from 'react-icons/hi'
import { StaticImage } from 'gatsby-plugin-image'
import UnderlineLink from 'pages/home/utils/UnderlineLink'
import { navigate } from 'gatsby'

function Intro() {
  const signUp = () => {
    navigate('https://app.walawong.com/auth/sign-up')
  }

  return (
    <Box
      as="section"
      bg={mode('gray.50', 'gray.800')}
      pt="24"
      pb="12"
      overflow="hidden"
    >
      <Flex
        align="center"
        direction={{ base: 'column-reverse', lg: 'row' }}
        justify="center"
      >
        <Box flex="1" maxW={{ lg: 'xl' }} px={{ base: 4, md: 10 }}>
          <Heading
            as="h1"
            size="3xl"
            mt="20"
            fontWeight="bold"
            color={mode('gray.900', 'gray.400')}
            // w='xl'
          >
            Your gateway to the <UnderlineLink>cryptoverse.</UnderlineLink>
          </Heading>
          <Text color={mode('gray.500', 'gray.400')} mt="70" fontSize="xl">
            You can now easily integrate crypto into your product offering. We
            offer a single API for you to connect to any crypto exchange,
            wallet, blockchain or accounts.
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4" mt="50">
            <Button
              size="lg"
              minW="210px"
              height="14"
              backgroundColor="brand.500"
              color="gray.100"
              px="8"
              onClick={signUp}
            >
              Get Started
            </Button>
            {/* <Button
              size="lg"
              backgroundColor={mode('#FFFFFF', '#FFFFFF')}
              color="gray.900"
              _hover={{ bg: mode('gray.100', 'gray.100') }}
              height="14"
              px="8"
              shadow="base"
              leftIcon={<Box as={HiPlay} fontSize="2xl" />}
            >
              Watch Demo
            </Button> */}
          </Stack>
          <Text mt="8" color={mode('gray.600', 'gray.400')}>
            Already have an account?{' '}
            <Link
              color={mode('brand.500', 'brand.400')}
              href={'https://app.walawong.com/auth/sign-in'}
            >
              Log in.
            </Link>
          </Text>
        </Box>
        <Flex justify="end" marginEnd={{ base: '0rem', lg: '-10rem' }}>
          <StaticImage
            width={740}
            height={473}
            src="../../assets/images/HeroImage.png"
            alt="walawong-intro"
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export default Intro
